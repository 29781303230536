<template>
  <b-card
    :header="$i18n.t('navigation.role-management')"
    header-bg-variant="primary"
    header-text-variant="white"
    class="checkboxWrapper"
  >
    <b-modal
      id="assign-role-modal"
      no-footer
      ok-only
      ok-variant="danger"
      size="md"
      :ok-title="$i18n.t('global.cancel')"
      centered
    >
      <b-form-group :label="chooseLabelText">
        <b-form-checkbox-group
          v-if="userRoles"
          v-model="selected"
          :options="userRoles"
          disabled-field="notEnabled"
          stacked
        />
      </b-form-group>
      <b-button
        v-if="userRoles"
        variant="primary"
        class="mt-2 mr-1 mb-2"
        @click="validationForm"
      >
        {{ $i18n.t("global.submit") }}
      </b-button>
    </b-modal>
    <b-table
      sticky-header
      :items="admins"
      :fields="fields"
      bordered
      responsive
      hover
      class="shadow-sm rounded products-table"
      thead-tr-class="order-main-thead"
      primary-key="id"
      show-empty
      :empty-text="$t('global.no-items')"
      small
      @row-clicked="(e) => onRowClick(e)"
    >
      <template #cell(action)="data">
        <b-button
          variant="flat-primary"
          class="btn-icon"
          @click="onRowClick(data.item)"
        >
          <feather-icon size="16" icon="EditIcon" />
        </b-button>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { required, email } from "@validations";
import {
  BFormGroup,
  BButton,
  BTable,
  BModal,
  BCard,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
// import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
import roles from "./roles.json";

export default {
  components: {
    BFormGroup,
    BButton,
    BTable,
    BFormCheckboxGroup,
    BModal,
    BCard,
  },
  data() {
    return {
      required,
      email,
      selectedUserId: null,
      selected: [],
      options: roles,
      fields: [
        {
          key: "action",
          label: this.$i18n.t("global.action"),
        },
        {
          key: "id",
          label: "Id",
        },
        {
          key: "userName",
          label: this.$i18n.t("roleManagement.username"),
        },
        {
          key: "email",
          label: this.$i18n.t("roleManagement.email"),
        },
        {
          key: "mobile",
          label: this.$i18n.t("roleManagement.phone"),
        },
      ],
    };
  },
  computed: {
    ...mapState("roleManagement", ["userRoles", "admins"]),
    labelText() {
      return `Choose roles for admin with email: ${this.userEmail}`;
    },
    chooseLabelText() {
      if (this.userRoles) {
        return this.$i18n.t("users.choseAndSave");
      }
      return "";
    },
  },
  watch: {
    userRoles(val) {
      console.log(val);
      this.selected = val
        .filter((res) => res.isEnabled)
        .map((ele) => ele.value);
    },
  },
  created() {
    this.getAdmins();
  },
  beforeDestroy() {
    this.$store.commit("roleManagement/CLEAR_USER_ROLES");
  },
  methods: {
    ...mapActions("roleManagement", [
      "setUserRole",
      "getUserRole",
      "getAdmins",
    ]),
    onRowClick(e) {
      this.selectedUserId = e.id;
      this.getUserRole({
        email: e.email,
        id: e.id,
      }).then(() => {
        this.$bvModal.show("assign-role-modal");
      });
    },
    validationForm() {
      this.setUserRole({
        id: this.selectedUserId,
        roles: this.userRoles.map((x) => ({
          id: x.roleId,
          isEnabled: this.selected.includes(x.value),
        })),
      }).then(() => {
        this.$bvModal.hide("assign-role-modal");
        Swal.fire({
          position: "center",
          icon: "success",
          text: this.$i18n.t("roleManagement.role-update-success"),
        });
        this.getAdmins();
      });
    },
  },
};
</script>

<style lang="scss">
.checkboxWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputAndButtonWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  @media (max-width: 600px) {
    flex-direction: column;
    height: unset;
  }
}

.getRolesButton {
  width: 20%;
  @media (max-width: 600px) {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 15px;
  }
}

.inputAndButtonWrapper input {
  width: 70%;
  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
  height: 100%;
}
</style>
